import React from "react"
import "./project.css"
import ImageGallery from "react-image-gallery"
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css"
import ProjectPage from "../components/projectpage";
const images = [
    {
      original: require('../images/this-website/milo.jpg'),
      thumbnail: require('../images/this-website/milo.jpg')
    },
  ];

const IndexPage = ({ data }) => {

  return (<div className="background">
    <ProjectPage>
        <h1>This Website</h1>
        <h4>May 2020 - Present</h4>
        <p>I built this website in May 2020, and have been updating it since then. I built it from scratch using React and Gatsby. It's hosted on Netlify, which is redirecting to this domain. I feel like having pictures of the website you're currently on would be pointless so here's a picture of my cute dog:</p>
        <ImageGallery showFullscreenButton={false} showPlayButton={false} items={images}></ImageGallery>

  </ProjectPage>
  </div>)
}

export default IndexPage
